<template>
    <CHeader fixed with-subheader light>
        <CToggler in-header class="ml-3 d-lg-none" @click="$store.commit('toggleSidebarMobile')" />
        <CToggler in-header class="ml-3 d-md-down-none" @click="$store.commit('toggleSidebarDesktop')" />
        <CHeaderBrand class="mx-auto d-lg-none" to="/">
            <img src="/img/logo-kementan.png" class="logo-img" style="max-height:36px"/>
            <b>
                Kementrian Pertanian <br />
                Republik Indonesia 
            </b>
        </CHeaderBrand>
        <CHeaderNav class="d-md-down-none mr-auto">
            <CHeaderNavItem style="min-width: 250px">
                <!-- <div class="input-group mb-0">
                    <input type="text" class="form-control" placeholder="Masukkan Kode Permohonan" />
                    <div class="input-group-append">
                        <span class="input-group-text"><i class="fa fa-search"></i></span>
                    </div>
                </div> -->
            </CHeaderNavItem>

            <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem> -->
        </CHeaderNav>
        <CHeaderNav class="mr-sm-4">
            <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0 mt-3">
                <template #toggler>
                    <CHeaderNavLink>
                        <CIcon name="cil-bell" />
                    </CHeaderNavLink>
                </template>
                <CDropdownHeader tag="div" class="text-center" color="light">
                    <strong>Notifikasi</strong>
                </CDropdownHeader>
                <div v-for="(value_list_notif, key_list_notif) in list_notif" :key="key_list_notif">
                    <CDropdownItem v-on:click="readMessage(value_list_notif.id, value_list_notif.data.offer_id, value_list_notif.data.message.offerUrl)"> {{value_list_notif.data.message.body}} </CDropdownItem>
					<CDropdownDivider />
                </div>
                
            </CDropdown>


            <!-- <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0 mt-3">
                <template #toggler>
                    <CHeaderNavLink>
                        <CIcon name="cil-envelope-open" />
                    </CHeaderNavLink>
                </template>
                <CDropdownHeader tag="div" class="text-center" color="light">
                    <strong>Account</strong>
                </CDropdownHeader>
                <CDropdownItem> <CIcon name="cil-user" /> Profile </CDropdownItem>
                <CDropdownItem> <CIcon name="cil-settings" /> Settings </CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem> <CIcon name="cil-shield-alt" /> Lock Account </CDropdownItem>
                <CDropdownItem> <CIcon name="cil-lock-locked" /> Logout </CDropdownItem>
            </CDropdown> -->
            <TheHeaderDropdownAccnt />
        </CHeaderNav>
        <CSubheader class="px-3">
            <CBreadcrumbRouter class="border-0 mb-0" />
        </CSubheader>
    </CHeader>
</template>

<script>
    import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

    export default {
        name: "TheHeader",
        components: {
            TheHeaderDropdownAccnt,
        },
        data() {
            return {
                list_notif: [],
                
            };
        },
        methods: {
            readMessage(id_notif, id_permohonan, link_permohonan){
            	Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
						text: "Mohon tunggu sebentar...",
						showConfirmButton: false,
					allowOutsideClick: false,
				});
            	let self = this;
            	axios
	                .get(this.apiLink + "api/mark-notification", {
	                    params:{
	                        id: id_notif
	                    },
	                    headers: {
	                        Authorization: "Bearer " + this.access_token,
	                    },
	                })
	                .then((response) => {
	                    var res_count = response.data;
	                    var res_count_data = res_count.data;
	                    
	                    if (res_count.meta.code != "200") {
	                        Swal.fire({
	                            icon: "error",
	                            title: "Oops...",
	                            text: res_count.data.message,
	                        });
	                    } else {
	                    	if (link_permohonan.includes('pemasukan')) {
		                    	self.$router.push({name: 'DetailPermohonanPemasukan', params: { idpermohonan: id_permohonan }});
	                    	}
	                    	else{
	                    		self.$router.push({name: 'DetailPermohonanPengeluaran', params: { idpermohonan: id_permohonan }});
	                    	}
	                    }
	                });
            }
        },
        created(){
            // Get list Notification
            axios
                .get(this.apiLink + "api/get-notification", {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_list = response.data;
                    var res_list_data = res_list.data;
                    
                    if (res_list.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_list.data.message,
                        });
                    } else {
                    	
                        this.list_notif = res_list_data;
                        console.log(this.list_notif);
                        
                    }
                });
        }
    };
</script>
